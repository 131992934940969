import React from 'react';
import { Link } from 'gatsby';
import 'font-awesome/less/font-awesome.less';
import style from './footer.module.less';
import '../../../styles/global.less';

export default () => {
  const year = new Date().getFullYear();

  return (
    <div className={style.footer}>
      &copy; ThinkBright&nbsp;
      {year}
      &nbsp;|&nbsp;
      <Link to="/impressum">
        Impressum
      </Link>
    </div>
  );
};
