import React from 'react';
import { Layout, Row, Col } from 'antd';
import Header from '../../components/PageLayout/Header';
import SidebarWrapper from '../../components/PageLayout/Sidebar';
import ContactForm from '../../components/PageFragments/ContactForm';
import Footer from '../../components/PageLayout/Footer';
import SEO from '../../components/Seo';

const Contact = () => (
  <Layout className="outerPadding">
    <Layout className="container">
      <SEO
        title="Kontakt"
        description=""
        path="/kontakt"
        keywords={['Gerald', 'Drausinger', 'ThinkBright', 'Agile Coaching', 'Product Owner', 'Scrum Master', 'Business Analyse', 'Projektmanagement']}
      />
      <Header />
      <SidebarWrapper>
        <div className="marginTopTitle">
          <h1 className="titleSeparate">Kontakt</h1>
        </div>
        <p>
          Sie benötigen Unterstützung bei der Umstellung auf agile
          Vorgehensweisen oder haben Fragen zu unserem Unternehmen?
          <br />
          Egal was Ihr Anliegen ist, schreiben Sie uns, wir melden uns
          schnellstmöglich bei Ihnen und betreuen Sie individuell.
        </p>
        <Row gutter={[40, 20]}>
          <ContactForm />
          <Col sm={24} md={24} lg={12}>
            <img
              src="../../message.jpg"
              alt="Kontakt"
              className="widthFull contactImgBorder"
            />
          </Col>
        </Row>
      </SidebarWrapper>
      <Footer />
    </Layout>
  </Layout>
);

export default Contact;
